import { EnumToArray } from '../../../utils/enumToArray';

enum Kind {
  Default = 'default',
  Combo = 'combo',
  Sale = 'sale',
  Unavailable = 'unavailable',
}

enum Size {
  SizeSm = 'sm',
  SizeMd = 'md',
  SizeLg = 'lg',
}

export const PTZSelectorConfigList = {
  Kind: EnumToArray([Kind]),
  Size: EnumToArray([Size])
};

export const PTZSelectorConfig = {
  Kind,
  Size
};
